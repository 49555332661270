<template>
  <!-- tab -->
  <div class="relative" :class="{ 'scroll-x': matchSize('md') }">
    <!-- ghost -->
    <div :id="`${props.id}-ghost`"
      class="will-change-auto absolute rounded-lg shadow top-0 left-0 bg-gradient-to-r from-lime-500 to-cyan-500 shadow-sky-500/50" />
    <!-- tab button  -->
    <nav class="relative rounded-lg flex items-center gap-2" :class="{
      'flex-wrap': props.wrap
    }">
      <button :id="`${props.id}-${tab.field}`" class="will-change-auto rounded-lg py-1 pl-1 pr-2 flex items-center gap-2"
        :class="{
          'bg-black/50 hover:bg-zinc-800/50 text-zinc-200': nowTab !== tab.field,
          'text-white': nowTab === tab.field
        }" v-for="(tab, tabid) in props.tabs" :key="`tab-${tabid}`" @click="tabTo(tab)">
        <component :is="tab.icon" class="flex-none size-6 stroke-2" />
        <span class="text-sm font-bold whitespace-nowrap select-none">{{ tab.name }}</span>
      </button>

      <slot />
    </nav>
  </div>
</template>

<script setup>
import gsap from 'gsap'
import { Flip } from 'gsap/Flip'
gsap.registerPlugin(Flip)
gsap.config({ force3D: true })

const emits = defineEmits(['change'])
const nowTab = ref()
const { matchSize } = useRwd()
const props = defineProps({
  id: {
    type: String,
    default: 'tab',
  },
  now: {
    type: String,
    default: '',
  },
  tabs: {
    type: Array,
    default: [],
  },
  wrap: {
    type: Boolean,
    default: false,
  },
})

onMounted(() => {
  if (props.now) {
    setTimeout(() => {
      if (document.querySelector(`#${props.id}-${props.now}`)) {
        Flip.fit(`#${props.id}-ghost`, `#${props.id}-${props.now}`, {
          duration: 0.2,
          ease: 'power2.inOut',
          absolute: true,
          simple: true,
        })
        nowTab.value = props.now
      }
    }, 500)
  }
})
////////////////////////////////////////////////////////////////
// tab transition
const tabTo = (tab) => {
  Flip.fit(`#${props.id}-ghost`, `#${props.id}-${tab.field}`, {
    duration: 0.2,
    ease: 'power2.inOut',
    absolute: true,
    simple: true,
  })

  nowTab.value = tab.field
  emits('change', tab.field)
}
</script>